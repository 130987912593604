import { createSelector } from 'reselect'

import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getUser = (state: AllState) => localState(state).user

export const getUserId = (state: AllState): number | null => {
  const user = getUser(state)

  if (!user) return null

  return user.id
}

export const getUserCountryCode = createSelector(getUser, user => user?.country_code)

export const getCountryId = (state: AllState): number | null => {
  const user = getUser(state)

  if (!user) return null

  return user.country_id
}

export const getAnonId = (state: AllState) => localState(state).anon_id
export const getTrackingPlatform = (state: AllState) => localState(state).tracking_platform
export const getTrackerDebugPin = (state: AllState) => localState(state).tracker_debug_pin
export const getLanguageCode = (state: AllState) => localState(state).languageCode

export const getCountryCode = createSelector(localState, ({ countryCode }) => countryCode)

export const getExposee = createSelector(
  [getAnonId, getUserId, getCountryCode],
  (anonId, userId, countryCode) => ({
    anonId,
    userId,
    countryCode,
  }),
)

'use client'

import { useState } from 'react'

import { isResponseSuccessful } from '@marketplace-web/shared/api-client'
import { redirectToPage, useLocation } from '@marketplace-web/shared/browser'
import { cookiesDataByName, useCookie } from '@marketplace-web/shared/cookies'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useSession } from '@marketplace-web/shared/session'

import { changeLanguageEvent } from '_libs/common/event-tracker/events'
import { updateUserLanguage } from 'data/api'
import { LanguageDto } from 'types/dtos/language'

import { removeLocaleUrlParam } from './utils'

type Props = {
  activeLanguage: LanguageDto
  setActiveLanguage: (language: LanguageDto) => void
}

const useLanguageSelector = ({ activeLanguage, setActiveLanguage }: Props) => {
  const { user, screen } = useSession()
  const userId = user?.id

  const cookies = useCookie()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { relativeUrl, urlQuery } = useLocation()
  const { track } = useTracking()

  const hideMenu = () => {
    setIsMenuOpen(false)
  }

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState)
  }

  const saveLanguage = async (language: LanguageDto) => {
    hideMenu()

    track(
      changeLanguageEvent({
        fromLanguage: activeLanguage?.code || null,
        toLanguage: language.code,
        screen,
      }),
    )

    if (userId) {
      const response = await updateUserLanguage({ userId, locale: language.code })

      if (!isResponseSuccessful(response)) return
    } else {
      cookies.set(cookiesDataByName['anonymous-locale'], language.code)
    }

    setActiveLanguage(language)

    cookies.delete(cookiesDataByName.locale)
    redirectToPage(removeLocaleUrlParam(relativeUrl, urlQuery))
  }

  return {
    isMenuOpen,
    toggleMenu,
    hideMenu,
    saveLanguage,
  }
}

export default useLanguageSelector

type Props = {
  text: string
  url: string
  testId?: string
}

const SectionLink = ({ text, url, testId }: Props) => {
  return (
    <li className="main-footer__privacy-section-item">
      <a className="main-footer__privacy-section-link" href={url} data-testid={testId}>
        {text}
      </a>
    </li>
  )
}
export default SectionLink

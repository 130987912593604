import { cookiesDataByName, useCookie } from '@marketplace-web/shared/cookies'
import { useSystemConfiguration } from '@marketplace-web/shared/system-configuration'

import { CookieConsentVersion } from '../constants'

const useConsent = () => {
  const cookies = useCookie()
  const { cookieConsentVersion } = useSystemConfiguration() || {
    cookieConsentVersion: CookieConsentVersion.None,
  }
  const isCookieConsentVersion = cookieConsentVersion !== CookieConsentVersion.None
  const optanonConsentCookie = () => cookies.get(cookiesDataByName.OptanonConsent) || ''

  return {
    cookieConsentVersion,
    isCookieConsentVersion,
    optanonConsentCookie,
  }
}

export default useConsent

import { ReactNode } from 'react'

type Props = {
  title: string
  children: ReactNode
}

const LinkSectionGroup = ({ title, children }: Props) => (
  <li className="main-footer__links-section-item">
    <div className="main-footer__links-section-label">{title}</div>
    <ul className="main-footer__links-section-group">{children}</ul>
  </li>
)

export default LinkSectionGroup

'use client'

import { useLayoutEffect } from 'react'
import { useLocation as useRouterLocation } from 'react-router-dom'

import { useLocation } from '@marketplace-web/shared/browser'

const RequestLocationUpdater = () => {
  const { pathname, search, hash } = useRouterLocation()
  const { refreshUrl } = useLocation()

  useLayoutEffect(() => {
    refreshUrl()
  }, [refreshUrl, pathname, search, hash])

  return null
}

export default RequestLocationUpdater

import { withServerSideProxy } from '@marketplace-web/shared/ssr'

import { ConsentGroup } from '../constants'

export function checkConsentGroup(group: ConsentGroup, optanonCookie?: string) {
  if (!optanonCookie) return false

  const optanonConsentParams = new URLSearchParams(optanonCookie)
  const optanonConsentGroups = optanonConsentParams.get('groups')?.split(',') || []

  return optanonConsentGroups.includes(group)
}

export const { toggleOneTrustInfoDisplay } = withServerSideProxy(
  {
    toggleOneTrustInfoDisplay: () => {
      window.OneTrust?.ToggleInfoDisplay()
    },
  },
  {},
)

'use client'

import type { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { StaticRouter, StaticRouterProps } from 'react-router-dom/server'

import RequestLocationUpdater from './RequestLocationUpdater'

type Props = {
  location?: StaticRouterProps['location']
  basename?: string
  isServerSide?: boolean
  children: ReactNode
}

/**
 * @deprecated use next navigation instead
 */
const CompatibleRouter = ({ location = '/', isServerSide = false, basename, children }: Props) => {
  if (isServerSide)
    return (
      <StaticRouter location={location} basename={basename}>
        {children}
      </StaticRouter>
    )

  return (
    <BrowserRouter basename={basename}>
      <RequestLocationUpdater />
      {children}
    </BrowserRouter>
  )
}

export default CompatibleRouter

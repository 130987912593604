'use client'

import { Image } from '@vinted/web-ui'
import { useIntl } from 'react-intl'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useAsset } from '@marketplace-web/shared/ui-helpers'
import { Hostname } from '@marketplace-web/shared/utils'
import { clickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

enum Platform {
  Android = 'android',
  Ios = 'ios',
}

const url: Record<Platform, string> = {
  [Platform.Android]: `https://${Hostname.Fr}/l/app-android-landing`,
  [Platform.Ios]: `https://${Hostname.Fr}/l/app-ios-landing`,
}
const clickTarget: Record<Platform, ClickableElement> = {
  [Platform.Android]: ClickableElement.DownloadAndroidApp,
  [Platform.Ios]: ClickableElement.DownloadIosApp,
}

type Props = {
  platform: Platform
}

const AppButton = ({ platform }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('app_store_buttons')
  const { locale } = useIntl()
  const asset = useAsset(`/assets/app-badges/${platform}`)

  const language = locale.split('-')[0]

  function handleClick() {
    track(
      clickEvent({
        target: clickTarget[platform],
      }),
    )
  }

  return (
    <a href={url[platform]} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
      <Image
        src={asset(`${language!}.svg`)}
        fallbackSrc={asset('/default.svg')}
        alt={translate(`a11y.${platform}`)}
      />
    </a>
  )
}

AppButton.Platform = Platform

export default AppButton

'use client'

import { Navigate } from 'react-router-dom'

import { navigateToPage } from '@marketplace-web/shared/browser'
import { useSession } from '@marketplace-web/shared/session'

type Props = {
  redirectRoute?: string
  redirectRailsRoute?: string
  children: JSX.Element
}

/**
 * @deprecated use next navigation instead
 */
const AuthenticatedRoute = ({ redirectRoute = '/', redirectRailsRoute, children }: Props) => {
  const isAuthenticated = useSession().user

  if (!isAuthenticated) {
    if (redirectRailsRoute) {
      navigateToPage(redirectRailsRoute)

      return null
    }

    return <Navigate to={redirectRoute} replace />
  }

  return children
}

export default AuthenticatedRoute

// Based on UserPhoto::PHOTO_SIZES
export enum UserThumbnailSize {
  Small = 'thumb20',
  Medium = 'thumb30',
  Default = 'thumb50',
  Large = 'thumb70',
  XLarge = 'thumb90',
  X2Large = 'thumb100',
  X3Large = 'thumb140',
  X4Large = 'thumb150',
  X5Large = 'thumb175',
  X6Large = 'thumb310',
}

import { isCurrencyAmountDto } from '_libs/utils/currency-amount'
import { CurrencyAmountDto, NullableCurrencyAmountDto } from 'types/dtos/currency-amount'
import { CurrencyAmountModel, NullableCurrencyAmountModel } from 'types/models/currency-amount'

export const transformCurrencyAmountDto = ({
  amount,
  currency_code,
}: CurrencyAmountDto): CurrencyAmountModel => {
  return {
    amount,
    currencyCode: currency_code,
  }
}

export const transformCurrencyAmountDtoOrString = (
  dtoOrString: CurrencyAmountDto | string,
  fallbackCurrencyCode: string,
): CurrencyAmountModel => {
  if (isCurrencyAmountDto(dtoOrString)) return transformCurrencyAmountDto(dtoOrString)

  return {
    amount: dtoOrString,
    currencyCode: fallbackCurrencyCode,
  }
}

export const transformNullableCurrencyAmountDto = ({
  amount,
  currency_code,
}: NullableCurrencyAmountDto): NullableCurrencyAmountModel => {
  if (amount && currency_code) {
    return {
      amount,
      currencyCode: currency_code,
    }
  }

  return {
    amount: null,
    currencyCode: null,
  }
}

import { createSelector } from 'reselect'

import { serverSide } from '@marketplace-web/shared/environment'
import { getScreenName } from 'state/screen/selectors'
import {
  getAnonId,
  getLanguageCode,
  getTrackerDebugPin,
  getTrackingPlatform,
  getUserId,
} from 'state/session/selectors'
import { getPortal as getSystemConfigPortal } from 'state/system-configuration/selectors'

import { TrackingEventContext } from '../types'
import { getScreenUtmParams } from './helpers'

export const getEventTrackerContext = createSelector(
  getAnonId,
  getUserId,
  getSystemConfigPortal,
  getLanguageCode,
  getTrackerDebugPin,
  getTrackingPlatform,
  getScreenName,
  (
    anonId,
    userId,
    systemConfigPortal,
    languageCode,
    debugPin,
    platform,
    screenName,
  ): TrackingEventContext => {
    const utmParams = getScreenUtmParams()
    const portal = systemConfigPortal || ''

    return {
      anonId,
      userId,
      portal,
      languageCode,
      debugPin,
      platform,
      screen: {
        width: serverSide ? 0 : window.screen.width,
        height: serverSide ? 0 : window.screen.height,
        name: screenName,
      },
      utm: {
        campaign: utmParams.utm_campaign,
        source: utmParams.utm_source,
        medium: utmParams.utm_medium,
        content: utmParams.utm_content,
        term: utmParams.utm_term,
      },
    }
  },
)

'use client'

type Props = {
  text: string
  url: string
  onClick?: () => void
  testId?: string
}

const LinkSectionItem = ({ text, url, onClick, testId }: Props) => (
  <li className="main-footer__links-section-row">
    <a
      className="main-footer__links-section-link"
      href={url}
      onClick={onClick}
      data-testid={testId}
    >
      {text}
    </a>
  </li>
)

export default LinkSectionItem

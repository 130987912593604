import Relay from './relay'
import Store from './store'
import EventTracker from './client-event-tracker'

const EVENT_TRACKER_STORE_NAMESPACE = 'VintedEvents'
const EVENT_TRACKER_RELAY_URL = '/relay/events'

const store = new Store(EVENT_TRACKER_STORE_NAMESPACE)
const relay = new Relay(EVENT_TRACKER_RELAY_URL)
const globalTracker = new EventTracker({ store, relay })

// TODO: remove this after redux cleanup
export { globalTracker }

import { UserThumbnailSize } from '../constants/photo'

type Thumbnails<T extends string> =
  | null
  | undefined
  | { thumbnails: Array<{ type: T; url: string }> }

export const findThumbnail = <T extends string>(
  photo: Thumbnails<T>,
  size: UserThumbnailSize | T = UserThumbnailSize.Default,
): string | null => {
  return photo?.thumbnails.find(({ type }) => type === size)?.url || null
}

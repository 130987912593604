import { Store as ReduxStore } from '@reduxjs/toolkit'
import { throttle } from 'lodash'

import { clientSideMetrics } from '@marketplace-web/shared/metrics'
import { isPrSandboxHostname } from '@marketplace-web/shared/utils'

import { TrackingEvent, TrackingEventContext } from '../types'
import { buildEvent, buildRequestHeaders } from './helpers'
import { getEventTrackerContext } from './redux-selector'
import Relay from './relay'
import Store from './store'

export type EventTrackerOptions = {
  relay: Relay
  store: Store
  contextSelector?: (state: any) => TrackingEventContext
}

type MetricParams = {
  counterMetricName: string
}

const EVENT_SYNC_RATE = 1000
export const FAILURES_TO_DISABLE = 3

class EventTracker {
  eventStore: Store

  relay: Relay

  context: TrackingEventContext | null = null

  reduxStore: ReduxStore | null = null

  errorState = {
    consecutiveFailures: 0,
    isDisabled: false,
  }

  contextSelector: ((state: any) => TrackingEventContext) | null = null

  // Used when requested events could not be built (due to unavailable context, for example)
  eventQueue: Array<TrackingEvent> = []

  syncThrottled = throttle(this.sync, EVENT_SYNC_RATE)

  constructor({ relay, store, contextSelector }: EventTrackerOptions) {
    this.eventStore = store
    this.relay = relay

    this.contextSelector = contextSelector || getEventTrackerContext
  }

  initialize(store: ReduxStore) {
    this.reduxStore = store

    this.handleReduxStoreChange()
    store.subscribe(this.handleReduxStoreChange)
  }

  handleReduxStoreChange = () => {
    if (!this.reduxStore || !this.contextSelector) return

    this.updateContext(this.contextSelector(this.reduxStore.getState()))
    this.drainEventQueue()
  }

  drainEventQueue() {
    if (!this.eventQueue.length) return

    this.eventQueue.forEach(event => this.track(event))
    this.eventQueue = []
  }

  updateContext(context: TrackingEventContext) {
    this.context = context
  }

  track = (event: TrackingEvent, metricParams?: MetricParams) => {
    if (metricParams) clientSideMetrics.counter(metricParams.counterMetricName).increment()

    if (this.errorState.isDisabled) return

    // Context may not be available yet, therefore we need to queue the event for it to be built and
    // sent at a later time.
    if (!this.context) {
      this.eventQueue.push(event)

      return
    }

    const preparedEvent = buildEvent(event, this.context)

    this.eventStore.add(preparedEvent)
    this.syncThrottled()
  }

  sync() {
    if (!this.context) return

    const events = this.eventStore.events.pending
    const headers = buildRequestHeaders(this.context)

    const success = () => {
      this.errorState.consecutiveFailures = 0

      this.eventStore.clear('outgoing')
    }

    const failure = () => {
      // temporary workaround so that event tracking can be manually tested in PR sandboxes
      // should be removed when ephemeral environments are reworked
      const isPrSandbox = isPrSandboxHostname(window.location.hostname)

      this.errorState.consecutiveFailures += 1

      if (this.errorState.consecutiveFailures >= FAILURES_TO_DISABLE) {
        if (!isPrSandbox) this.errorState.isDisabled = true
        this.eventQueue = []
        this.eventStore.clear('outgoing')
        this.eventStore.clear('pending')

        return
      }

      // Add outgoing events back to pending queue
      events.forEach(event => this.eventStore.add(event))

      this.eventStore.clear('outgoing')
    }

    this.eventStore.movePending()
    this.relay.transport({ payload: events, headers, success, failure })
  }
}

export default EventTracker

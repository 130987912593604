import { serverSide } from '@marketplace-web/shared/environment'

export const isNativeDatePickerSupported = () => {
  if (serverSide) return false

  const input = document.createElement('input')

  input.setAttribute('type', 'date')
  input.setAttribute('value', '2000-01-01')

  return !!input.valueAsDate
}
